.container {
    overflow-y: scroll;
}

.container::-webkit-scrollbar {
    display: none;
    /* Safari and Chrome browsers */
}

.container {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* mozilla */
}

.text-wrapper {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    display: inline-block;
    width: 200px;
}